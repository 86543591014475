import { render, staticRenderFns } from "./KTCollab.vue?vue&type=template&id=3cbab0c6&scoped=true"
import script from "./KTCollab.vue?vue&type=script&lang=js"
export * from "./KTCollab.vue?vue&type=script&lang=js"
import style0 from "./KTCollab.vue?vue&type=style&index=0&id=3cbab0c6&prod&lang=css"
import style1 from "@/assets/css/collaboration/collaborfont.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./KTCollab.vue?vue&type=style&index=2&id=3cbab0c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cbab0c6",
  null
  
)

export default component.exports